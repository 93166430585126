import Button from "../Button";
import {
  ButtonBox,
  HiTextBox,
  InfoTextBox,
  NameTextBox,
  TextBox,
} from "./styles";

import { FaGithubAlt } from "react-icons/fa";

const MainText = () => {
  return (
    <TextBox>
      <HiTextBox>Hi there, my name is</HiTextBox>
      <NameTextBox>Lorena Martiniana</NameTextBox>
      <InfoTextBox>
        and I’m a front-end web developer based in the great state of Minas
        Gerais, Brazil!
      </InfoTextBox>
      <ButtonBox>
        <Button text="Github profile" icon={<FaGithubAlt />} />
      </ButtonBox>
    </TextBox>
  );
};

export default MainText;
