import ContentContainer from "../ContentContainer";
import DetailsTitle from "../DetailsTitle";
import GridContainer from "../GridContainer";
import SectionTitle from "../SectionTitle";
import Button from "../Button";

import {
  ButtonBox,
  ButtonsContainer,
  ProjectDescription,
  ProjectImg,
  ProjectsSection,
  SingleProject,
} from "./styles";

import img from "../../img/png/book_klub.png";

import { FaGithubAlt, FaExternalLinkAlt } from "react-icons/fa";
import MediumButton from "../MediumButton";
import StackList from "../StackList";

const Projects = () => {
  return (
    <ProjectsSection id="projects">
      <ContentContainer>
        <SectionTitle text="Latest projects" side="left" />

        <GridContainer>
          <SingleProject>
            <ProjectImg src={img} alt="Book Klub" />
            <DetailsTitle>Book Klub</DetailsTitle>
            <StackList />
            <ProjectDescription>
              Book Klub is a REST API developed with Node.JS and Express, and
              written in Typescript. It allows users do register, login and
              create book clubs, where they can shcedule meetings and debate
              about their favourite books!
            </ProjectDescription>

            <ButtonsContainer>
              <ButtonBox>
                <MediumButton text="view on github" icon={<FaGithubAlt />} />
              </ButtonBox>

              <ButtonBox>
                <MediumButton text="view live" icon={<FaExternalLinkAlt />} />
              </ButtonBox>
            </ButtonsContainer>
          </SingleProject>

          <SingleProject>
            <ProjectImg src={img} alt="Book Klub" />
            <DetailsTitle>Book Klub</DetailsTitle>
            <StackList />
            <ProjectDescription>
              Book Klub is a REST API developed with Node.JS and Express, and
              written in Typescript. It allows users do register, login and
              create book clubs, where they can shcedule meetings and debate
              about their favourite books!
            </ProjectDescription>

            <ButtonsContainer>
              <ButtonBox>
                <MediumButton text="view on github" icon={<FaGithubAlt />} />
              </ButtonBox>

              <ButtonBox>
                <MediumButton text="view live" icon={<FaExternalLinkAlt />} />
              </ButtonBox>
            </ButtonsContainer>
          </SingleProject>

          <SingleProject>
            <ProjectImg src={img} alt="Book Klub" />
            <DetailsTitle>Book Klub</DetailsTitle>
            <StackList />
            <ProjectDescription>
              Book Klub is a REST API developed with Node.JS and Express, and
              written in Typescript. It allows users do register, login and
              create book clubs, where they can shcedule meetings and debate
              about their favourite books!
            </ProjectDescription>

            <ButtonsContainer>
              <ButtonBox>
                <MediumButton text="view on github" icon={<FaGithubAlt />} />
              </ButtonBox>

              <ButtonBox>
                <MediumButton text="view live" icon={<FaExternalLinkAlt />} />
              </ButtonBox>
            </ButtonsContainer>
          </SingleProject>

          <SingleProject>
            <ProjectImg src={img} alt="Book Klub" />
            <DetailsTitle>Book Klub</DetailsTitle>
            <StackList />
            <ProjectDescription>
              Book Klub is a REST API developed with Node.JS and Express, and
              written in Typescript. It allows users do register, login and
              create book clubs, where they can shcedule meetings and debate
              about their favourite books!
            </ProjectDescription>

            <ButtonsContainer>
              <ButtonBox>
                <MediumButton text="view on github" icon={<FaGithubAlt />} />
              </ButtonBox>

              <ButtonBox>
                <MediumButton text="view live" icon={<FaExternalLinkAlt />} />
              </ButtonBox>
            </ButtonsContainer>
          </SingleProject>
        </GridContainer>
      </ContentContainer>
    </ProjectsSection>
  );
};

export default Projects;
