import styled from "styled-components";

export const Container = styled.div`
  width: 63%;
  margin: 0 auto;
  padding: 100px 0;

  @media (max-width: 1410px) {
    width: 90%;
  }
`;
