import styled from "styled-components";

export const Menu = styled.nav`
  width: 40%;
`;

export const MenuList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
`;

export const MenuListItem = styled.li`
  color: var(--bright-blue);
  font-family: "Space Mono", monospace;
  font-size: 20px;

  cursor: pointer;

  a {
    color: var(--bright-blue);
    transition: all 0.3s;
  }

  a:hover {
    color: var(--bright-pink);
  }
`;
