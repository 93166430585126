import styled from "styled-components";

export const ButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;

  border: 2px solid var(--bright-blue);
  border-radius: 5px;

  color: var(--bright-blue);
  font-family: "Space Mono", monospace;
  font-size: 1.2rem;
  line-height: 3rem;

  background-color: transparent;

  cursor: pointer;

  &:hover {
    border-color: var(--bright-pink);
    color: var(--bright-pink);
  }
`;
