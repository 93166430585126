import styled from "styled-components";

export const AboutSection = styled.section`
  background-color: var(--dark-purple);
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-align: justify;
`;

export const AboutText = styled.div`
  width: 70%;

  p {
    line-height: 2rem;
    letter-spacing: 0.05rem;
    font-size: 0.9rem;
    margin: 20px 0;
  }
`;

export const TextHighlight = styled.span`
  color: var(--bright-pink);
`;

export const AboutPicture = styled.div`
  width: 20%;
`;

export const ProfileImg = styled.img`
  border-radius: 100%;
  max-width: 13vw;
  max-height: 13vw;
`;

export const HalfBox = styled.div`
  text-align: left;
`;

export const Divider = styled.div`
  background-color: var(--medium-blue);
  height: 1px;
  margin: 30px 0;
`;

export const SmallText = styled.div`
  color: var(--light-blue);
  font-size: 0.7rem;
  font-family: "Space Mono", monospace;
  margin-top: 8px;
`;

export const SmallTextBox = styled.div`
  font-size: 0.85rem;
  line-height: 2rem;
  margin-top: 20px;
`;
