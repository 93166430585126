import { Menu, MenuList, MenuListItem } from "./styles";

const Navigation = () => {
  return (
    <Menu>
      <MenuList>
        <MenuListItem>
          <a href="#about">About me</a>
        </MenuListItem>
        <MenuListItem>
          <a href="#projects">Projects</a>
        </MenuListItem>
        <MenuListItem>
          <a href="#contact">Contact me</a>
        </MenuListItem>
      </MenuList>
    </Menu>
  );
};

export default Navigation;
