import ContentContainer from "../ContentContainer";
import SectionTitle from "../SectionTitle";
import {
  ContactContainer,
  ContactForm,
  ContactFormBox,
  ContactIcons,
  ContactInfo,
  ContactSection,
  ContactText,
  FormInput,
  FormTextarea,
  Icon,
} from "./styles";

import { FaGithub, FaLinkedin } from "react-icons/fa";

const Contact = () => {
  return (
    <ContactSection id="contact">
      <ContentContainer>
        <SectionTitle text="Get in touch" side="right" />

        <ContactContainer>
          <ContactFormBox>
            <ContactForm>
              <FormInput placeholder="Name" size="large" />
              <FormInput placeholder="E-mail" />
              <FormInput placeholder="Subject" />
              <FormTextarea placeholder="Message" />
            </ContactForm>
          </ContactFormBox>

          <ContactInfo>
            <ContactText>
              Wanna know more about me or start a partnership? Hit me up through
              the contact form, or get in touch through my social networks!
            </ContactText>

            <ContactIcons>
              <Icon>
                <FaGithub />
              </Icon>
              <Icon>
                <FaLinkedin />
              </Icon>
            </ContactIcons>
          </ContactInfo>
        </ContactContainer>
      </ContentContainer>
    </ContactSection>
  );
};

export default Contact;
