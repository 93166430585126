import { TechList } from "./styles";

import Tech from "../Tech";

const StackList = () => {
  const stackList = ["Node.js", "Typescript", "Express", "Docker"];

  return (
    <TechList>
      {stackList.map((tech, index) => (
        <Tech name={tech} key={index} />
      ))}
    </TechList>
  );
};

export default StackList;
