import styled from "styled-components";

export const TechBox = styled.div`
  background-color: var(--medium-blue3);
  padding: 5px 8px;
  margin-top: -30px;
  margin-bottom: 25px;
  border-radius: 5px;

  font-family: "Space Mono", monospace;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;

  transition: all 0.3s;

  cursor: default;

  &:hover {
    background-color: var(--bright-pink);
  }
`;
