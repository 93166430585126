import { SkillBar, SkillBox, SkillName } from "./style";

const Skill = ({ name, size }) => {
  return (
    <SkillBox>
      <SkillBar size={size}></SkillBar>
      <SkillName>{name}</SkillName>
    </SkillBox>
  );
};

export default Skill;
