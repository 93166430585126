import ContentContainer from "../ContentContainer";
import SectionTitle from "../SectionTitle";
import DetailsTitle from "../DetailsTitle";
import InfoTitle from "../InfoTitle";

import {
  AboutPicture,
  AboutSection,
  AboutText,
  Divider,
  HalfBox,
  InfoContainer,
  ProfileImg,
  SmallText,
  SmallTextBox,
  TextHighlight,
} from "./styles";

import img from "../../img/gif/profile_img.gif";
import GridContainer from "../GridContainer";
import Skill from "../Skill";

const About = () => {
  return (
    <AboutSection id="about">
      <ContentContainer>
        <SectionTitle text="The developer & her crafts" side="right" />

        <InfoContainer>
          <AboutText>
            <p>
              I’ve started my career in tech as a web designer. I used to{" "}
              <TextHighlight>design interfaces</TextHighlight> for websites and
              mobile apps, and sometimes I would even code a bit in{" "}
              <TextHighlight>CSS</TextHighlight> and
              <TextHighlight> HTML</TextHighlight>. As time went by, I’ve came
              to the realization that I was enjoying the coding part of my job
              much more than the designing part.
            </p>

            <p>
              Long story short, in 2020 I’ve decided to make a switch, and went
              from full time designer to an{" "}
              <TextHighlight>information systems student</TextHighlight>, and
              finally <TextHighlight>developer</TextHighlight>!
            </p>

            <p>
              I live for single player <TextHighlight>RPGs</TextHighlight> and
              turn based strategy games,
              <TextHighlight> crocheting</TextHighlight> cute amigurumis and
              drinking massive cups of{" "}
              <TextHighlight>cappuccino </TextHighlight>
              to the sound of <TextHighlight>heavy metal</TextHighlight> while
              coding!
            </p>
          </AboutText>

          <AboutPicture>
            <ProfileImg
              src={img}
              alt="Animated GIF with a drawing of Lorena Martiniana"
            />
          </AboutPicture>
        </InfoContainer>

        <GridContainer>
          <DetailsTitle>Skill set</DetailsTitle>
          <HalfBox>
            <Skill name="Html" size="90" />
            <Skill name="CSS" size="80" />
            <Skill name="JavaScript" size="60" />
            <Skill name="Node.js" size="50" />
          </HalfBox>

          <HalfBox>
            <Skill name="TypeScript" size="60" />
            <Skill name="Python" size="40" />
            <Skill name="C#" size="20" />
            <Skill name="Docker" size="50" />
          </HalfBox>
        </GridContainer>

        <Divider />

        <GridContainer>
          <DetailsTitle>Education</DetailsTitle>

          <HalfBox>
            <InfoTitle>System Analysis and Development</InfoTitle>
            <SmallText>PUC - Minas | 2024 - 2026</SmallText>

            <SmallTextBox>
              Currently working on getting my degree in System Analysis and
              Development. This is an higher education degree, equivalent to an
              associate degree, with a focus on computer systems and software
              engineering.
            </SmallTextBox>
          </HalfBox>

          <HalfBox>
            <InfoTitle>Full stack developer</InfoTitle>
            <SmallText>Kenzie Academy Brasil | Jan/2022 - Jan/2023</SmallText>

            <SmallTextBox>
              Currently working on getting my bachelor’s degree in Information
              Systems. This is an undergraduate course, similar to computer
              science but with a hint of administration and economics.
            </SmallTextBox>
          </HalfBox>
        </GridContainer>
      </ContentContainer>
    </AboutSection>
  );
};

export default About;
