import styled from "styled-components";

export const SkillBox = styled.div`
  margin: 10px 0;
  text-align: left;
`;

export const SkillBar = styled.div`
  width: ${(props) => props.size + "%"};
  background-color: var(--bright-pink);
  height: 6px;
  border-radius: 50px;
`;

export const SkillName = styled.div`
  font-family: "Source Code Pro", monospace;
  padding: 10px 0;
  color: var(--bright-blue);
`;
