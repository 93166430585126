import styled from "styled-components";

export const TextBox = styled.div`
  min-width: 530px;
  width: 42%;

  display: flex;
  flex-direction: column;

  margin: 0 auto;
`;

export const HiTextBox = styled.div`
  color: var(--bright-blue);
  font-family: "Space Mono", monospace;
`;

export const NameTextBox = styled.h1`
  color: var(--bright-pink);
  font-weight: 800;
  margin: 5% 0 10% 0;
`;

export const InfoTextBox = styled.div`
  color: var(--gray);
  line-height: 3rem;
`;

export const ButtonBox = styled.div`
  width: 40%;
  margin: 0 auto;
  margin-top: 10%;
`;
