import { Title, TitleBox } from "./styles";

const DetailsTitle = ({ children }) => {
  return (
    <TitleBox>
      <Title>{children}</Title>
    </TitleBox>
  );
};

export default DetailsTitle;
