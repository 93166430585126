import { ButtonIcon, ButtonStyle, ButtonText } from "./styles";

const MediumButton = ({ text, icon }) => {
  return (
    <ButtonStyle>
      <ButtonIcon>{icon}</ButtonIcon>
      <ButtonText>{text}</ButtonText>
    </ButtonStyle>
  );
};

export default MediumButton;
