import styled from "styled-components";

export const ProjectsSection = styled.section`
  background-color: var(--medium-blue2);
`;

export const ProjectsContainer = styled.div`
  width: 70%;
`;

export const SingleProject = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 40px;
`;

export const ProjectImg = styled.img`
  width: 100%;
`;

export const ProjectDescription = styled.div`
  font-size: 0.9rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonBox = styled.div`
  width: 45%;
`;
