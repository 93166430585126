import styled from "styled-components";

export const ContactSection = styled.div`
  width: 100%;
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 20px;
`;

export const ContactFormBox = styled.div`
  width: 54%;
`;

export const ContactForm = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FormInput = styled.input`
  width: ${(props) => (props.size === "large" ? "100%" : "49%")};
  background-color: var(--medium-blue3);

  color: var(--bright-blue);
  font-family: "Space Mono", monospace;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;

  border: none;
  border-radius: 6px;
  padding: 12px 10px;
  margin: 7px 0;

  &::placeholder {
    color: var(--bright-pink);
  }
`;

export const FormTextarea = styled.textarea`
  width: 100%;
  min-height: 150px;
  background-color: var(--medium-blue3);

  color: var(--bright-blue);
  font-family: "Space Mono", monospace;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;

  border: none;
  border-radius: 6px;
  padding: 12px 10px;
  margin: 7px 0;

  &::placeholder {
    color: var(--bright-pink);
  }
`;

export const ContactInfo = styled.div`
  width: 42%;

  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  line-height: 2rem;
`;

export const ContactText = styled.div`
  text-align: right;
`;

export const ContactIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;

  margin-top: 40px;
`;

export const Icon = styled.div`
  color: var(--bright-pink);
  font-size: 2.5rem;

  transition: all 0.3s;

  cursor: pointer;

  &:hover {
    color: var(--bright-blue);
  }
`;
