import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    :root {
            --gray: #E6E7E8;
            --dark-blue: #1A1E37;
            --medium-blue: #303760;
            --medium-blue2: #1C2141;
            --medium-blue3: #1E244A;
            --dark-purple: #251A37;
            --bright-pink: #FD367E;
            --bright-blue: #00FAAC;
            --light-blue: #CEFFF0;
        }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
    }

    a {
        text-decoration: none;
    }

    body {
        font-family: 'Mulish', sans-serif;
        color: var(--gray);
        background-color: #1a1e37;
        background-image: linear-gradient(#251a37 0%, #1a1e37 46%, #1a1e37 100%);
        font-size: 16px;
    }

`;
