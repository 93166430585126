import Logo from "../Logo";
import Navigation from "../Navigation";
import { HeaderContainer } from "./styles";
import { useState } from "react";

const Header = () => {
  const [headerNav, setHeaderNav] = useState(false);

  const changeHeaderBg = () => {
    if (window.scrollY >= 100) {
      setHeaderNav(true);
      console.log(headerNav);
    } else {
      setHeaderNav(false);
    }
  };

  window.addEventListener("scroll", changeHeaderBg);

  return (
    <HeaderContainer active={headerNav}>
      <Logo />
      <Navigation />
    </HeaderContainer>
  );
};

export default Header;
