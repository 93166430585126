import { Title, TitleBar, TitleContainer } from "./styles";

const SectionTitle = ({ text, side }) => {
  return (
    <TitleContainer>
      {side === "right" ? (
        <>
          <TitleBar />
          <Title side={side}>{text}</Title>
        </>
      ) : (
        <>
          <Title side={side}>{text}</Title>
          <TitleBar />
        </>
      )}
    </TitleContainer>
  );
};

export default SectionTitle;
