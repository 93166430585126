import styled from "styled-components";

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleBar = styled.div`
  background-color: var(--bright-blue);

  border-radius: 10px;

  height: 2px;
  width: 100%;
`;

export const Title = styled.h2`
  color: var(--bright-blue);
  font-family: "Space Mono", monospace;
  font-size: 2rem;
  font-weight: 400;
  white-space: nowrap;

  margin: ${(props) => (props.side === "left" ? "0 50px 0 0" : "0 0 0 50px")};
`;
