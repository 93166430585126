import styled from "styled-components";

export const TitleBox = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  margin: 30px 0;
  text-align: left;
`;

export const Title = styled.h3`
  font-family: "Space Mono", monospace;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: var(--bright-blue);
`;
