import { ButtonStyle } from "./styles";

const Button = ({ text, icon }) => {
  return (
    <ButtonStyle>
      {icon}
      {text}
    </ButtonStyle>
  );
};

export default Button;
