import "./App.css";

import GlobalStyle from "./styles/global";
import ParticlesOptions from "./components/Particles";
import Header from "./components/Header";
import MainText from "./components/MainText";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <header className="App-header">
        <Header />
        <MainText />
        <ParticlesOptions />
      </header>
      <div className="bg"></div>
      <About />
      <Projects />
      <div className="bg"></div>
      <Contact />
    </div>
  );
}

export default App;
