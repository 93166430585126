import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  height: 100px;
  width: 100vw;
  padding: 0 5%;

  transition: all 0.3s;

  background-color: ${(props) =>
    props.active === true ? "var(--dark-blue)" : "transparent"};
`;
